const SEO = [
	{
		page: "home",
		description:
			"Hello. I am Amman and welcome to my website. My experience has touched nearly all aspects of computer science. " + 
			"I built scalable web applications, performed UI/UX work, analyzed, displayed and collected data, trained machine learning models, " +
			"handled distributed applications, and traversed  backend worked with ease (not so easy while doing it). I am a passionate learner and " + 
			"always look to tackle challenging problems. My curiosity  moves me from project to project, but I never fail to land on new challenges and " +
			"exciting problems to overcome as a computer scientist 🧑‍💻",
		keywords: ["Amman", "Yusuf", "Amman Y", "Amman Yusuf"],
	},

	{
		page: "about",
		description:
			"My interest in coding started in my high school robotics class where I coded up battle bots (with arduino brains) " +
			"to duke it out in a high stakes tournament. From there my passion in coding blossomed at the University of Calgary, where I graduated first class. " + 
			"Through my degree I took on various projects and internships. While varied, the common theme between my experience is challenging, interesting, and fulfilling work. " + 
			"I started developing websites (I still do) in my first role, which got me involved in AWS, which then got working with data in my data science role, which opened up my " +
			"interest in machine learning, which got me interested in computer vision and deep learning, which culminated (so far) in my final year thesis project in Federated Learning. " +
			"Apart from work experience, I still have a great interest in distributed applications and theory. One of my favourite courses from my degree was 'Introduction to Distributed Algorithms " + 
			"where we proved the correctness of distributed algorithms. It was a delight understanding how the algorithms work and the proofs at the end were no short of amazing.",
		keywords: ["Amman", "Yusuf", "Amman Y", "Amman Yusuf"],
	},

	{
		page: "articles",
		description:
			"Some throughts and ideas I like to type out. They are related to coding, personal projects, or other random stuff I find.",
		keywords: ["Amman", "Yusuf", "Amman Y", "Amman Yusuf"],
	},

	{
		page: "projects",
		description:
			"Worked on projects that span front-end development, back-end development, machine learning, research, and computer vision.",
		keywords: ["Amman", "Yusuf", "Amman Y", "Amman Yusuf"],
	},

	{
		page: "contact",
		description:
			"If you're interested in working together on a project, feel free to reach out!",
		keywords: ["Amman", "Yusuf", "Amman Y", "Amman Yusuf"],
	},
];

export default SEO;
